<template>
  <div class="box">
    <div class="headline">题库管理</div>
    <div class="second_line">6.6 专题题库</div>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/49.png')"
      :preview-src-list="[require('../../assets/Bankimg/49.png')]"
    >
    </el-image>
    <span class="text"> 专题题库主要是针对专题的，比如防风防汛、地震等应对题目。操作方式可参照题库维护。 </span>
   
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
  
  <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  img {
  }
}
</style>